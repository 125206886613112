:root {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Source Code Pro',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  transition: background-color 0.15s ease-out;
}

span {
  line-height: normal;
}

.rainbow-animated {
  background: linear-gradient(99.85deg,
    /* Base gradient stops */
    rgb(179, 46, 255), rgb(242, 164, 152),
    /* Repeat your base gradient stops */
    rgb(179, 46, 255), rgb(242, 164, 152),
    /* Repeat your first gradient stop */
    rgb(179, 46, 255));
  animation: rainbowAnimation 6s linear infinite;
  background-size: 200% 200%;
}

@keyframes rainbowAnimation {
  0% { background-position: 100% 100% }
  100% { background-position: 0% 0% }
}
